import React from 'react'
import { Link } from "react-router-dom";

function Joinm() {
  return (
    <div> 
        <div className="album py-2">
      <div className="container">
        <h2>Membership Plan of <b>MISAA</b></h2>
      </div>
    </div>

      <div className="album py-2">
        <div className="container">
          <div className="row">
            <div className="col-md-4 mt-3">
              <div className="card text-dark bg-info mb-3">
                <div className="card-header"><center>LIFETIME MEMBER</center></div>
                <center>
                  <div className="card-body">

                    <br />
                    <p className="card-text">Enroll for lifetime membership</p>
                    <br />
                    <Link to="/Lifetime">
                      <div className='btn-grad30'>LIFETIME & VIEW DETAILS</div>
                    </Link>
                  </div>
                </center>
              </div>
            </div>
            <div className="col-md-4 mt-3">
              <div className="card text-danger bg-light mb-3">
                <div className="card-header"><center> GENERAL MEMBERSHIP</center></div>
                <center>
                  <div className="card-body">

                    <br />
                    <p className="card-text">Enroll for  membership</p>
                    <br />
                    <Link to="/Yearly_member">
                      <button className='lifetimecard'> VIEW DETAILS</button>
                    </Link>
                  </div>
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Joinm