import React from 'react'
import Header from "../inc/Header";
import {Link} from "react-router-dom";

function Lifetime() {
  return (
    <div>
        <Header/>
        
        <br/>
        <br/>
        <br/>
        <br/>
        <div className='bestberhamporeschool'>
                <div className="album py-1 ">
                    <div className="container mt-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/" style={{ color: "darkblue" }} href="#">Home</Link></li>

                                <li className="breadcrumb-item active" aria-current="page">Lifetime Membership<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                    <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                </svg> </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <br/>
        <div className="album py-2">
        <div className="container">
        <h1 style={{color:"grey"}}>Lifetime Member <b>Facility:</b></h1>
        <h2 className="card-title m-3 ">INR 10000</h2>
        <p>Update soon</p>
        </div>
        </div>
    </div>
  )
}

export default Lifetime