import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import { Link } from 'react-router-dom';
import { db } from '../../firebase'





function CurrentNews() {
    const [setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(450);
    const [pagination, setPagination] = useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start, end) => {
        setPagination({ start: start, end: end });
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [member, setMember] = useState([])
    const navigate = useNavigate()

    const memberCollectionRef = collection(db, "CURRENT-NEWS");
    useEffect(() => {

        const getMember = async () => {
            const data = await getDocs(memberCollectionRef);
            setMember(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }

        getMember()
    }, [])
    return (

        <>




            <br />




            <div className="album py-1">

                <div className="container">
                    <div className="row">
                        <div className="col-md-7">
                            <div className='schoolberhampore'>
                                <h2 className='blink mt-2' >M.I.S.A.A <b>Upcoming Events Bulletin</b> :</h2>
                                <hr />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />

            <div className="album py-4">

                <div className="container">

                    <div className="row">
                        {member.filter((val) => {
                            if (setsearchTerm === "") {
                                return val;
                            } else if (
                                val.name.toLowerCase().includes(searchTerm.toLowerCase())




                            ) {
                                return val;
                            }
                        }).slice(pagination.start, pagination.end).map((misaa) => {
                            return (

                                <div className="col-md-3">
                                    <div className="card mb-4 border-White box ">
                                        <img className="card-img-top" src={misaa.img} alt={misaa.name} />
                                        <div className="card-body">
                                            <h4 className="card-text"> <b>{misaa.name}</b></h4>
                                            <h6 className="card-text"> <b>{misaa.contact}</b></h6>



                                        </div>
                                    </div>

                                </div>
                            )
                        })}
                    </div>

                </div>
            </div>

        </>

    );
}

export default CurrentNews;