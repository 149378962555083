


import React, { useEffect, useState, } from 'react'

// import Medical from '../pages/Medical';

import Header from '../inc/Header';
// import Uploadpres from '../pages/Uploadpres';
import { Badge, Dropdown, Container, Nav, Form, FormControl, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { storage } from '../../firebase';
import { ref, uploadBytes, listAll, getDownloadURL } from "firebase/storage";
import { v4 } from "uuid";
import bankdetails from "../images/bank-details.png";


import { Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom'
import { useUserAuth } from '../../contexts/UserAuthContext'
import { collection, getDocs, getDoc, doc, query, where, onSnapshot, addDoc, arrayUnion, setDoc, updateDoc } from "firebase/firestore";
import { db } from '../../firebase'



const CSV_FILE_URL = 'https://misaa.org.in/misaa_membership_list.csv'




function UserDashboard(props) {
    const [selectedImage, setSelectedImage] = useState(null);
    const { logOut } = useUserAuth()
    const [photoURL, setPhotoURL] = useState()
    const navigate = useNavigate()
    const { user, state: { cart } } = useUserAuth();
    const userCollectionRef = collection(db, "newusers")
    const [labtests, setLabtests] = useState([])
    const labtestRef = collection(db, "Labtests")
    const [consults, setConsults] = useState([])
    const consultRef = collection(db, "Consults")
    const [checkups, setCheckups] = useState([])
    const checkupRef = collection(db, "Checkups")
    const [userinfo, setUserInfo] = useState([]);
    const [appointmentinfo, setAppointmentInfo] = useState([]);
    const [labtestbookinfo, setLabtestbookinfo] = useState([]);
    const userLabtestBookingRef = collection(db, "LabtestBookings")
    // const userBookingRef = collection(db, "AllBookings")
    const [consultbookinfo, setConsultbookinfo] = useState([]);
    const [checkupbookinfo, setCheckupbookinfo] = useState([]);


    const [userss, setUser] = useState([])

    const [imageUpload, setImageUpload] = useState(null);
    const [imageList, setImageList] = useState([]);


    const downloadFileAtURL = (url) => {
        const fileName = url.split('/').pop()
        const aTag = document.createElement('a')
        aTag.href = url
        aTag.setAttribute('download', fileName)
        document.body.appendChild(aTag)
        aTag.click();
        aTag.remove();
    };




    useEffect(() => {
        const f = collection(db, "newusers");
        const pp = query(f, where("email", "==", user.email));
        onSnapshot(pp, (snapshot) => {
            let u = []
            snapshot.docs.forEach((doc) => {
                u.push({ ...doc.data(), id: doc.id })
            })
            // console.log(users)
            setUser(u)
        })
    }, []);

    var photoRef
    userss.map((uinfo) => {
        const f = doc(db, "newusers", uinfo.email)
        photoRef = f
    })
    let p = [];
    userinfo.map((url) => {
        p = url.records
    })

    // console.log(p)

    const imageListRef = ref(storage, "images/")
    const uploadImage = () => {
        if (imageUpload == null) return;

        const imageRef = ref(storage, `images/${imageUpload.name + v4()}`);
        uploadBytes(imageRef, imageUpload).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((url) => {

                updateDoc(photoRef, {
                    records: arrayUnion(url)
                });


                setImageList((prev) => [...prev, url])
                alert("Image Uploaded Successful");
            })

        });
    };

    useEffect(() => {
        listAll(imageListRef).then((response) => {
            response.items.forEach((item) => {
                getDownloadURL(item).then((url) => {
                    setImageList((prev) => [...prev, url]);
                });
            });
        });
    }, []);

    // const q = query(userCollectionRef, where("email", "==", user.email));
    // const querySnapshot = getDocs(q);

    // if (!querySnapshot.empty){
    //   querySnapshot.forEach((doc) => {
    //     console.log(doc.id, " => ", doc.data());
    //   });
    // }


    useEffect(() => {
        const ref = collection(db, "newusers");
        const q = query(ref, where("email", "==", user.email));
        onSnapshot(q, (snapshot) => {
            let users = []
            snapshot.docs.forEach((doc) => {
                users.push({ ...doc.data(), id: doc.id })
            })
            // console.log(users)
            setUserInfo(users)
        })
    }, [])

    useEffect(() => {
        const newref = collection(db, "Appointments");
        const q = query(newref, where("email", "==", user.email));
        onSnapshot(q, (snapshot) => {
            let appointments = []
            snapshot.docs.forEach((doc) => {
                appointments.push({ ...doc.data(), id: doc.id })
            })

            setAppointmentInfo(appointments)
            //  console.log(appointments)
        })
    }, [])

    useEffect(() => {
        const ref = collection(db, "LabtestBookings");
        const b = query(ref, where("email", "==", user.email));
        onSnapshot(b, (snapshot) => {
            let labtestbooking = []
            snapshot.docs.forEach((doc) => {
                labtestbooking.push({ ...doc.data(), id: doc.id })
            })
            // console.log(labtestbooking)
            setLabtestbookinfo(labtestbooking)
        })
    }, [])

    useEffect(() => {
        const ref = collection(db, "ConsultBookings");
        const g = query(ref, where("email", "==", user.email));
        onSnapshot(g, (snapshot) => {
            let consultbooking = []
            snapshot.docs.forEach((doc) => {
                consultbooking.push({ ...doc.data(), id: doc.id })
            })
            // console.log(consultbooking)
            setConsultbookinfo(consultbooking)
        })
    }, [])


    // useEffect(() => {
    //   const ref =collection(db,"AllBookings");
    //   const b = query(ref, where("email", "==", user.email), where("type", "==", "labtest"));
    //   onSnapshot(b,(snapshot) => {
    //     let labtestbooking=[]
    //     snapshot.docs.forEach((doc)=>{
    //       labtestbooking.push({...doc.data(),id:doc.id})
    //     })
    //     // console.log(labtestbooking)
    //     setLabtestbookinfo(labtestbooking)
    //   })
    // },[])

    // useEffect(() => {
    //   const ref =collection(db,"AllBookings");
    //   const g = query(ref, where("email", "==", user.email), where("type", "==", "consult"));
    //   onSnapshot(g,(snapshot) => {
    //     let consultbooking=[]
    //     snapshot.docs.forEach((doc)=>{
    //       consultbooking.push({...doc.data(),id:doc.id})
    //     })
    //     // console.log(consultbooking)
    //     setConsultbookinfo(consultbooking)
    //   })
    // },[])

    // useEffect(() => {
    //   const ref =collection(db,"AllBookings");
    //   const m = query(ref, where("email", "==", user.email), where("type", "==", "checkup"));
    //   onSnapshot(m,(snapshot) => {
    //     let checkupbooking=[]
    //     snapshot.docs.forEach((doc)=>{
    //       checkupbooking.push({...doc.data(),id:doc.id})
    //     })
    //     // console.log(consultbooking)
    //     setCheckupbookinfo(checkupbooking)
    //   })
    // },[])


    useEffect(() => {
        const ref = collection(db, "ConsultBookings");
        const g = query(ref, where("email", "==", user.email));
        onSnapshot(g, (snapshot) => {
            let consultbooking = []
            snapshot.docs.forEach((doc) => {
                consultbooking.push({ ...doc.data(), id: doc.id })
            })
            // console.log(consultbooking)
            setConsultbookinfo(consultbooking)
        })
    }, [])

    const [userr, setUserr] = useState()
    const params = useParams()
    useEffect(() => {
        getData()
    }, [])
    async function getData() {
        try {
            const userrTemp = await getDoc(doc(db, 'newusers', params.userId))
            setUserr(userrTemp.data())

        } catch (error) {
            console.log(error.message)
        }
    }

    const { dispatch } = useUserAuth();
    useEffect(() => {
        const getlabtests = async () => {
            const data = await getDocs(labtestRef);
            setLabtests(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }
        getlabtests()
    }, [])

    useEffect(() => {
        const getconsults = async () => {
            const data = await getDocs(consultRef);
            setConsults(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }
        getconsults()
    }, [])


    const [total, setTotal] = useState();

    useEffect(() => {
        setTotal(
            cart.reduce((acc, curr) => acc + Number(curr.price) * curr.qty, 0)
        );
    }, [cart]);


    //Razorpay integration
    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement('script')
            script.src = src
            script.onload = () => {
                resolve(true)
            }
            script.onerror = () => {
                resolve(false)
            }
            document.body.appendChild(script)
        })
    }
    async function displayRazorpay(amount) {
        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

        if (!res) {
            alert('Razorpay SDK failed to load. Are you online?')
            return
        }

        const options = {
            // key: 'rzp_test_m1pxa5JKJbAV4p',
            key: 'rzp_live_dsnDbV7kqj8n52',
            currency: 'INR',
            amount: total * 100,
            name: 'Uniaone Family Payment',
            description: 'Razorpay Payment Gateway',
            image: "",
            handler: function (response) {
                alert(response.razorpay_payment_id)
                alert("Payment Succesfull")

                if (response.razorpay_payment_id) {
                    cart.map((i) => {
                        const usertestinfo = {
                            "email": user.email,
                            "detail": i.detail,
                            "name": i.name,
                            "price": i.price,
                            // "type": i.type
                        }
                        // addDoc(userBookingRef, usertestinfo)
                        addDoc(userLabtestBookingRef, usertestinfo)
                    })




                }


            },
            // if(response.razorpay_payment_id){

            // }
            prefill: {
                email: user.email,
            }
        }
        const paymentObject = new window.Razorpay(options)
        paymentObject.open()
    }
    const emailVerified = user.emailVerified;

    const handleLogOut = async () => {
        try {
            await logOut()
            window.location.reload()
            navigate("/")
        } catch (err) {

        }
    }



    return (
        <>

            <Header />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className='album py-2'>
                <div className='container'>
                    <ul className="nav nav-pills mb-3 text-ligh shadow me-4 " id="pills-tab" role="tablist">
                        <li className="nav-item me-4" role="presentation">
                            <button className="nav-link active me-4" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Dashborad</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Your House</button>
                        </li>

                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Payment Update List</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="pills-myappointment-tab" data-bs-toggle="pill" data-bs-target="#pills-myappointment" type="button" role="tab" aria-controls="pills-myappointment" aria-selected="false">Meet Event Notice</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="pills-numberupdate-tab" data-bs-toggle="pill" data-bs-target="#pills-numberupdate" type="button" role="tab" aria-controls="pills-numberupdate" aria-selected="false">Help Desk </button>
                        </li>
                    </ul>
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">

                            <div className='userinfo'>

                                {userinfo.map((uinfo) => {
                                    return (
                                        <div key={userinfo.id} style={{ margin: 30 }}>
                                            <h4 style={{ color: "green" }}> <center> Welcome to<b className='blink'> {uinfo.fullname}</b> </center></h4>
                                            <h5 style={{ color: "darkBlue" }}>
                                                <center>
                                                    Your email : {uinfo.email}
                                                </center>
                                            </h5>
                                            <h4 style={{ color: "grey" }}>
                                                <center>
                                                    <b>Your mobile : {uinfo.mobile}</b>
                                                </center>
                                            </h4>
                                        </div>
                                    )
                                })}
                            </div>


                            <br />


                            <div className="album py-3">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-8 mt-3">

                                            <div className='userinfo'>

                                                {userinfo.map((uinfo) => {
                                                    return (
                                                        <div key={userinfo.id} style={{ margin: 20 }}>
                                                            <div className="album py-1">

                                                                <div className="container">
                                                                    <h2 className='mb-3' > <b style={{ color: "darkBlue" }}>YOUR HOUSE: </b> <b style={{ color: "grey" }}>{uinfo.housecategory}</b> </h2>
                                                                    < h2 className='mb-3' > <b style={{ color: "darkBlue" }}>BLOOD GROUP:  </b> <b style={{ color: "grey" }}>{uinfo.bloodgroup}</b> </h2>
                                                                    < h2 > <b style={{ color: "darkBlue" }}>DATE OF BIRTH:  </b> <b style={{ color: "grey" }}>{uinfo.dateofbirth}</b> </h2>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                        <div className="col-md-4 mt-3">
                                            <div className="card" >

                                                <div className="card-body">

                                                    <h5>M.I.S.A.A. BANK DETAILS :-</h5>
                                                    Mary Immaculate School Alumni Association. <br />
                                                    UNION BANK OF INDIA. <br />

                                                    Account No. 548202010010629.<br />
                                                    IFSC CODE : UBIN0554821.<br />
                                                    SWIFT CODE : UBININBBOCL
                                                    <br />
                                                    <br />

                                                    <img src={bankdetails} height="192px" width="160px" alt='bank-details' />

                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>


                            {emailVerified ? (

                                <div className='right'>

                                    {appointmentinfo.map((ainfo) => {
                                        return (
                                            <div key={appointmentinfo.id} style={{ margin: 30 }}>

                                                <h3 style={{ margin: 30 }}>You Have Appointment With Dr. {ainfo.Appointment_with}</h3>
                                                <h3 style={{ margin: 30 }}>At : {ainfo.Time} </h3>
                                                <h3 style={{ margin: 30 }}>Date: {ainfo.Date}</h3>
                                            </div>
                                        )
                                    })}




                                    <div className="album py-3">
                                        <div className="container">
                                            <div className="row" >
                                                {labtestbookinfo.map((labinfo) => {

                                                    return (
                                                        <div className="col-6" key={labinfo.id}>
                                                            <div className="card mb-4 border-primary box shadow ">

                                                                <div className="card-body">
                                                                    <h5 className="card-text">{labinfo.name}</h5>
                                                                    <p className="card-text">{labinfo.detail}</p>
                                                                    <h6 className="card-text">₹{labinfo.price}</h6>
                                                                    <h6 className="card-text">{labinfo.type}</h6>
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>


                                    <div className="album py-3">
                                        <div className="container">
                                            <div className="row" >
                                                {consultbookinfo.map((consainfo) => {

                                                    return (
                                                        <div className="col-6" key={consainfo.id}>
                                                            <div className="card mb-4 border-primary box shadow ">

                                                                <div className="card-body">
                                                                    <p className="card-text">{consainfo.detail}</p>
                                                                    <h6 >₹{consainfo.price}</h6>

                                                                </div>
                                                            </div>
                                                        </div>

                                                    )
                                                })}
                                            </div>
                                        </div>

                                    </div>

                                    <div className="album py-0">
                                        <div className="container">
                                            <div className="row" >
                                                {checkupbookinfo.map((checkinfo) => {

                                                    return (
                                                        <div className="col-6" key={checkinfo.id}>
                                                            <div className="card mb-4 border-primary box shadow ">

                                                                <div className="card-body">
                                                                    <p className="card-text">{checkinfo.detail}</p>
                                                                    <h6 >₹{checkinfo.price}</h6>

                                                                </div>
                                                            </div>
                                                        </div>

                                                    )
                                                })}
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            ) : (
                                <div className='right'>
                                    <h2 style={{ display: "block", margin: 50 }}>You Need to Verify your Email to see the Appointments</h2>

                                </div>
                            )
                            }

                        </div>




                        <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                            <div className='userinfo'>

                                {userinfo.map((uinfo) => {
                                    return (
                                        <div key={userinfo.id} style={{ margin: 14 }}>
                                            <br />
                                            <h1 style={{ color: "goldenrod" }} > <center>Your house name:   {uinfo.housecategory} </center></h1>

                                            <h5 style={{ color: "maroon" }} >
                                                <center>
                                                    Your mobile : {uinfo.mobile}
                                                </center>
                                            </h5>
                                        </div>
                                    )
                                })}
                            </div>
                            <br />
                            <br />
                            <h3 style={{ color: "GREY", textAlign: "center" }}>YOUR HEALTH RECORD UPLOAD <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" style={{ color: "blue" }} fill="currentColor" class="bi bi-patch-check-fill" viewBox="0 0 16 16">
                                <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z" />
                            </svg></h3><br />
                            {emailVerified ? (
                                <div className="App">
                                    <input type="file" style={{ margin: 10 }} onChange={(event) => {
                                        setImageUpload(event.target.files[0])
                                    }} />
                                    <button onClick={uploadImage} class="btn btn-outline-success"> UPLOAD HEALTH RECORD </button><br /><br />
                                    {p.map((n) => {
                                        return (


                                            <a className="btn btn-primary btn-sm" href={n}>Download</a>

                                            // <img src={n} style={{  margin:15 }} class="img-fluid " width="200" height="260" alt=''></img>


                                        )
                                    })}


                                </div>

                            ) : (
                                <div className='right'>
                                    <h4 style={{ display: "block", margin: 50, color: "purple" }}>You Need to Verify your Email to see the Trading Records</h4>

                                </div>
                            )
                            }


                        </div>

                        <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">

                            {/* <Uploadpres/> */}

                            {/* <h2 style={{margin: 50}}>Yours Consults Bookings :- </h2>
    <div className="album py-3"> 
      <div className="container">
        <div className="row" style={{margin:5}}>
            {consultbookinfo.map((consainfo) => { 
            
            return (
              <div className="col-6" key={consainfo.id}>
                <div className="card mb-2 border-dark box shadow">
                  
                    <div className="card-body">
                    
                      <h5 className="card-text">{consainfo.detail}</h5>
                      <h6 >₹{consainfo.price}</h6>
                      <h6 >{consainfo.type}</h6>
          
                    </div>
                  
                </div>
              </div>
            )})}
          </div>
      </div>
    </div>
            */}



                            {/*   
  {/* <h4 style={{margin:30, color: "purple"}}>Your Orders</h4>
              
              {cart.length > 0 ?(
                <> 
                 {emailVerified ?(
                  <div className="album py-3">
          
                  <div className="container">
      
                      <div className="row">
                      {cart.map((consult) => { return (
                          <div className="col-md-3" key={consult.id}>
                            <div className="card mb-4 border-dark box-shadow">
                            <img className="card-img-top" src={consult.conImg} alt="doc1" />
                                <div className="card-body">
                                  <h5 className="card-text">{consult.name}</h5>
                                  <p className="card-text">{consult.detail}</p>
                                  <h6 className="card-text">₹{consult.price}</h6>
                                  <div className="d-flex justify-content-between align-items-center">
                               
                                  <button onClick={ () =>
                                    dispatch({
                                      type: "REMOVE_FROM_CART",
                                      payload: consult,
                                    })} className='btn btn-danger'>Remove</button>
                         
                                </div>
                           </div>
                           </div>
                         
                           </div>
                           
                         
                   
                        )})}
                        
                
                       
                        </div>
                        
                      </div>
  
                    
                   
                      <div className='checkout' style={{margin:10}}>
                        <span style={{ fontWeight: 700, fontSize: 20 }}>Total: ₹ {total}</span>
                        <Button  onClick={displayRazorpay} style={{margin:30}} type="button" disabled={cart.length === 0}>
                          Proceed to Checkout
                        </Button>
                      </div>
                  </div>
                  ):(
                    <h3 style={{ display: "block",margin:50 }}>You Need to Verify your Email to see Your Orders</h3>
                  )
                }
                
                </>
  
  
  
              ):(
                <h3 style={{margin:30}}>Cart is Empty!!!</h3>
              )} */}
                            {/* </div> */}
                            {/* {user.emailVerified ?(
            
              <div className='right'>
                <h3 style={{margin:30}}>Yours Appointments</h3>
                {appointmentinfo.map((ainfo) => { return (
                  <div key={appointmentinfo.id} style={{margin:30}}>
                    
                    <h3 style={{margin:30}}>You Have Appointment With Dr. {ainfo.Appointment_with}</h3>
                    <h3 style={{margin:30}}>At : {ainfo.Time} </h3>
                    <h3 style={{margin:30}}>Date: {ainfo.Date}</h3>
                  </div>
                )})}
              
                
              </div>
              ):(
                <div className='right'>
                <h2 style={{ display: "block",margin:50 }}>You Need to Verify your Email to see the Appointments</h2>
  
              </div>
              )
            // } */}
                            <br />
                            <div className="album py-3">
                                <div className="container">
                                    <center>

                                        <button onClick={() => {downloadFileAtURL(CSV_FILE_URL)}} className="payment btn-sm" >Payment Update List <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-circle-fill" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z" />
                                        </svg></button>
                                    </center>
                                </div>
                            </div>




                        </div>
                        <div class="tab-pane fade" id="pills-myappointment" role="tabpanel" aria-labelledby="pills-myappointment-tab">
                            <h2 style={{ color: "green", margin: 45 }}>MISAA Importance notice :- </h2>
                            <div className="album py-0">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-7">
                                            <div className='schoolberhampore'>
                                                <h2 className='blink mt-2' >M.I.S.A.A <b>Upcoming Events Bulletin</b> :</h2>
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="album py-1">

<div className="container">
  <div className="row">
    <div className="col-md-3">
      <div className="card  text-white bg-black mb-3">

        <div className="card-body">
          <h6 className="card-title textjustify">1) Taru Rakhi Bandhan. Annual Tree Plantation Initiative on Raksha Bandhan day, 19th August 2024. Everyone are welcome to join. Reporting time 06:30 AM Mary Immaculate School. <br /> <br /></h6>

        </div>
      </div>
    </div>
    <div className="col-md-3">
      <div className="card text-white bg-info mb-3">

        <div className="card-body">
          <h6 className="card-title textjustify">
            <center> <h3 style={{ color: "red" }} className='blink'>New</h3> </center>
            2) 
The upcoming Annual Football Match Between M.I.S teachers & staff Vs. M.I.S.A.A members slated for 13th September 2024 STANDS <b style={{color:"black"}}>CANCELLED</b> for now. ( Postponed until further notice )  This is in keeping with the grave crime committed in R.G.Kar Medical College on 9th August. In solidarity with ABHAYA, M.I.S.A.A demands Justice and without any further delay. 
<br/>

<h6 className='mt-3'>Let's pray for Abhaya and earnestly hope for Justice</h6>
             

          </h6>
        </div>
      </div>
    </div>
    <div className="col-md-3">
      <div className="card text-dark bg-light mb-3">

        <div className="card-body">
          <h6 className="card-title textjustify">
            <center> <h3 style={{ color: "red" }} className='blink'>New</h3> </center>
            3) Annual Blood Donation Camp on 5th of November 2024, Guru Nanak Jayanti Day. Venue M.I.S Auditorium. Commencement from 10:00 AM upto 01:00 PM. Everyone is requested to come forward & donate blood.</h6>
        </div>
      </div>
    </div>
    <div className="col-md-3">
      <div className="card text-white bg-primary mb-3">

        <div className="card-body">
          <h6 className="card-title  textjustify">
            <center> <h3 style={{ color: "red" }} className='blink'>New</h3> </center>
            4) Annual M.I.S.A.A get-together, musical night & dinner on 22nd December 2024 at school grounds. Program starts at 04:30 PM and ends at 09:30 PM. Event is for M.I.S.A.A members only. <br />
            <br>
            </br></h6>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
                            <div className="album py-3">
                                <div className="container">
                                    <div className="row" >
                                        {labtestbookinfo.map((labinfo) => {

                                            return (
                                                <div className="col-6" key={labinfo.id}>
                                                    <div className="card mb-2 border-dark box shadow">

                                                        <div className="card-body">
                                                            <h5 className="card-text">{labinfo.name}</h5>
                                                            <p className="card-text">{labinfo.detail}</p>
                                                            <h6 className="card-text">₹{labinfo.price}</h6>
                                                            <h6 className="card-text">{labinfo.type}</h6>
                                                            <div className="d-flex justify-content-between align-items-center">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <br />
                        <div class="tab-pane fade" id="pills-numberupdate" role="tabpanel" aria-labelledby="pills-numberupdate-tab">
                            <h4 style={{ textAlign: "center", color: "maroon" }}> Anywhere, At any time Email or call if you have a tech releted problem.</h4><br />

                            <div className="album py-2">

                                <div className="container">

                                    <div className="row">
                                        <div className="col-md-6 ">
                                            <div className="card mb-4 box shadow">
                                                <div className="card-body">
                                                    <a href='mailto: official@misaa.org.in'>
                                                        <h5 style={{ textAlign: "center" }}>Official Email Id: <button className="btn btn-outline-primary"> official@misaa.org.in </button></h5>
                                                    </a>
                                                    <div className="d-flex justify-content-between align-items-center">


                                                    </div>

                                                </div>


                                            </div>


                                        </div>
                                        <div className="col-md-6 ">
                                            <div className="card mb-4 box shadow">
                                                <div className="card-body">

                                                    <h5 style={{ textAlign: "center" }}>Help Line Number <a href="tel:+91 9733966161" className="btn btn- btn-outline-">+91 9733966161</a></h5>
                                                    <div className="d-flex justify-content-between align-items-center">


                                                    </div>

                                                </div>


                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div >
            </div >




            {/* <div className='doc-div' style={{ display: "flex", justifyContent: "space-between"}}>
          <div className='left'> */}





            {/* </div>
      
      </div> */}


            {/* 
      <div className='dashboard'>
        
    
        <h4 style={{textAlign: "center"}}> Welcome {user.email}</h4>
       
        <br></br> 

    

        <div className='doc-div' style={{ display: "flex", justifyContent: "space-between"}}>
          <div className='left'>
            <h4 style={{margin:30}}>Your Orders</h4>  
            {cart.length > 0 ?(
              <> 
                <div className='cart'>
                  <div className="labs">
                  {cart.map((labtest) => { return (
                      <div className="col" key={labtest.id}>
                        <div className="test1">
                          <div className="card text-center" >
                            <div className="card-body">
                              <h5 className="card-title">{labtest.name}</h5>
                              <p className="card-text">{labtest.detail}</p>
                              <h6 >₹{labtest.price}</h6>
                              <button onClick={ () =>
                                dispatch({
                                  type: "REMOVE_FROM_CART",
                                  payload: labtest,
                                })} className='btn btn-danger'>Remove</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )})}
                    </div>
                    <div className='checkout' style={{margin:30}}>
                      <span style={{ fontWeight: 700, fontSize: 20 }}>Total: ₹ {total}</span>
                      <Button  onClick={displayRazorpay} style={{margin:30}} type="button" disabled={cart.length === 0}>
                        Proceed to Checkout
                      </Button>
                    </div>
                  
                </div>
                
              </>
            ):(
              <h2 style={{margin:30}}>Cart is Empty!!!</h2>
            )}
          </div>
          <div className='right'>
            <h4 style={{margin:30, color: "blue"}}>Yours Appointments</h4>

          </div>
        </div>

     
       


      
      </div>
     */}



        </>
    )
}

export default UserDashboard