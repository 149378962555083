import React from 'react'

import Header from '../inc/Header'

function Terms() {
    return (
        <div>
            <Header />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className="album py-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                        <div className="card" >

  
  <div className="card-body">
    <center>
    <h4 className="card-title mb-4">Term & Conditions</h4>
    </center>
    <p className="card-text">I declare that i adhere to terms & conditions of membership as mentioned in website. Membership can be cancelled by admins if found flouting preconditions.</p>

  </div>
</div>
                        </div>
                    </div>
                </div>
            </div>
            <br/>
        </div>
    )
}

export default Terms