



import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
import "firebase/storage";



const firebaseConfig = {
  apiKey: "AIzaSyAf4uv4_22anCJHpdFmaiuJb18fH37_33o",
  authDomain: "misaa-berhampore.firebaseapp.com",
  projectId: "misaa-berhampore",
  storageBucket: "misaa-berhampore.appspot.com",
  messagingSenderId: "211318166155",
  appId: "1:211318166155:web:1c2e33d08060050fa1ba8e",
  measurementId: "G-KHD0GYV5VH"
 
};
//init firebase app
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);




export default app;



