import React from 'react'
import Header from '../inc/Header'

function Anthem() {
  return (
    <div>
        <Header/>
    
        <center>
        <br/>
        
        <div className="album py-2">
        <div className="container">
            <h3 style={{margin: "140px"}} className='textjustify ' >
            <h1 style={{color:"grey"}}><b>OUR ANTHEM</b></h1>
            <br/>

            With blessings from above, <br/>
We will walk our way, <br/>
Holding hands, once again <br/>
We will never go astray. <br/>
<br/>


We pray for consciousness , <br/>
We pray for true courage, <br/>
We pray for compassion , <br/>
To heal and an ailing world. <br/>
<br/> 
With love and brotherhood, <br/>
We will light the lamp, <br/>
That will lead us all, <br/> 
To blessed happiness. 

            </h3>
            </div>
            </div>

        </center>
    </div>
  )
}

export default Anthem