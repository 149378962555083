import React from 'react'
import Header from '../inc/Header'
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
function OfficeB() {
    return (
        <div>
            <Header />
            <br />
            <br />
            <br />
            <br />
            <ScrollToTop smooth top="100" color="light"/>
            <div className='bestberhamporeschool'>
                <div className="album py-1">
                    <div className="container mt-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/" style={{ color: "darkblue" }} href="#">Home</Link></li>

                                <li className="breadcrumb-item active" aria-current="page">Office Bearers <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                    <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                </svg> </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <br/>
            <div className="album py-2">
            <div className="container">
            <h2>Office Bearers</h2>
            </div>
            </div>
            <div className="album py-2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 mt-3">
                            <div className="card" >
                                <div className="card-body">
                                    <center>
                                        <h5 style={{color:"grey"}} className="card-title"><b>President  </b></h5>
                                        <h5 className="card-text">Dr. Dipayan Tarafdar</h5>
                                        <a href='tel: 9434148327'>Call: +91 9434148327</a>
                                    </center>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-3 mt-3">
                            <div className="card" >
                                <div className="card-body">
                                    <center>
                                        <h5 style={{color:"grey"}} className="card-title"><b>Vice President:                                        </b></h5>
                                        <h5 className="card-text">Mr. Subhamoy Mazumder                                        </h5>
                               
                                    </center>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-3 mt-3">
                            <div className="card" >
                                <div className="card-body">
                                    <center>
                                        <h5 style={{color:"grey"}} className="card-title"><b>Vice President:                                        </b></h5>
                                        <h5 className="card-text">Mr. Amit Jain                                       </h5>
                               
                                    </center>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-3 mt-3">
                            <div className="card" >
                                <div className="card-body">
                                    <center>
                                        <h5 style={{color:"grey"}} className="card-title"><b>Vice President:                                        </b></h5>
                                        <h5 className="card-text">Mr. Sudip Marothi                                        </h5>
                               
                                    </center>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-3 mt-3">
                            <div className="card" >
                                <div className="card-body">
                                    <center>
                                        <h5 style={{color:"grey"}} className="card-title"><b>Secretary:                                        </b></h5>
                                        <h5 className="card-text">Mr. Kasem Mallick                                        </h5>
                                        <a href='tel: 9434530745'>Call: +91 9434530745</a>
                                    </center>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-3 mt-3">
                            <div className="card" >
                                <div className="card-body">
                                    <center>
                                        <h5 style={{color:"grey"}} className="card-title"><b>Jt. Secretary:                                        </b></h5>
                                        <h5 className="card-text">Mr. Shibaji Sarkar                                        </h5>
                                        <a href='tel:  9434101829'>Call: +91 9434101829</a>
                                    </center>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-3 mt-3">
                            <div className="card" >
                                <div className="card-body">
                                    <center>
                                        <h5 style={{color:"grey"}} className="card-title"><b>Jt. Secretary:                                        </b></h5>
                                        <h5 className="card-text">Mr. Arijit Gupta                                        </h5>
                                        <a href='tel: 9733966161'>Call: +91 9733966161</a>
                                    </center>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-3 mt-3">
                            <div className="card" >
                                <div className="card-body">
                                    <center>
                                        <h5 style={{color:"grey"}} className="card-title"><b>Jt. Secretary:                                        </b></h5>
                                        <h5 className="card-text"> Sumona Mondal                                        </h5>
                                        {/* <a href='tel: 9733966161'>Call: +91 9733966161</a> */}
                                    </center>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-3 mt-3">
                            <div className="card" >
                                <div className="card-body">
                                    <center>
                                        <h5 style={{color:"grey"}} className="card-title"><b>Treasurer:                                        </b></h5>
                                        <h5 className="card-text"> Mr. Sanjay Kumar Sarkar                                       </h5>
                                        <a href='tel: 9434108762'>Call: +91 9434108762</a>
                                    </center>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-3 mt-3">
                            <div className="card" >
                                <div className="card-body">
                                    <center>
                                        <h5 style={{color:"grey"}} className="card-title"><b>Treasurer:                                        </b></h5>
                                        <h5 className="card-text"> Daisy Paul                                        </h5>
                                        {/* <a href='tel: 9733966161'>Call: +91 9733966161</a> */}
                                    </center>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-3 mt-3">
                            <div className="card" >
                                <div className="card-body">
                                    <center>
                                        <h5 style={{color:"grey"}} className="card-title"><b>Councillor:                                        </b></h5>
                                        <h5 className="card-text"> Monica Mondal                                      </h5>
                                        {/* <a href='tel: 9733966161'>Call: +91 9733966161</a> */}
                                    </center>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-3 mt-3">
                            <div className="card" >
                                <div className="card-body">
                                    <center>
                                        <h5 style={{color:"grey"}} className="card-title"><b>Councillor:                                        </b></h5>
                                        <h5 className="card-text"> Mr. Arindam Sen                                       </h5>
                          
                                    </center>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-3 mt-3">
                            <div className="card" >
                                <div className="card-body">
                                    <center>
                                        <h5 style={{color:"grey"}} className="card-title"><b>Councillor:                                        </b></h5>
                                        <h5 className="card-text"> Mr. Anirban Chandra                                     </h5>
                                      
                                    </center>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-3 mt-3">
                            <div className="card" >
                                <div className="card-body">
                                    <center>
                                        <h5 style={{color:"grey"}} className="card-title"><b>Cultural Secretary:                                        </b></h5>
                                        <h5 className="card-text"> Jatobeda Das Goswami                                  </h5>
                                      
                                    </center>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-3 mt-3">
                            <div className="card" >
                                <div className="card-body">
                                    <center>
                                        <h5 style={{color:"grey"}} className="card-title"><b>Cultural Secretary:                                        </b></h5>
                                        <h5 className="card-text"> Mr. Kapil Bhattachariya                                   </h5>
                                      
                                    </center>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-3 mt-3">
                            <div className="card" >
                                <div className="card-body">
                                    <center>
                                        <h5 style={{color:"grey"}} className="card-title"><b>Cultural Secretary:                                        </b></h5>
                                        <h5 className="card-text"> Mr. Amitava Mehta                                </h5>
                                      
                                    </center>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
<br/>

        </div>
    )
}

export default OfficeB