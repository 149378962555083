import React from 'react'

import { Link } from "react-router-dom";
import SrLalita from "../images/Sister Superior.jpg";

import ScrollToTop from "react-scroll-to-top";
import Header from '../inc/Header';

function SisterLalita() {
    return (
        <div>
            <Header />
            <br />
            <br />
            <br />
            <br />
            <ScrollToTop smooth top="100" color="light"/>
            <div className='bestberhamporeschool'>
                <div className="album py-1">
                    <div className="container mt-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/" style={{ color: "darkblue" }} href="#">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Sister Lalita<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                    <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                                </svg> </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="album py-2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 mt-3">
                            <div className="card">
                                <img src={SrLalita} className="card-img-top" alt="SrLalita" />

                            </div>
                        </div>
                        <div className="col-md-8 mt-3">


                            <div className="card-body">
                                <h5 className='mb-3'><b>Dear Members of MISAA</b></h5>
                                <h5 className="card-title textjustify mb-3">It is with love and gratitude to God our loving Father that I pen up this few lines to appreciate you for the efforts that you have made in serving the humanity through your different philanthropic activities.  In the past,  you had been conducting the Blood Donation Camp to save  human life. You also went about planting trees being Taru Mitra and upholding the ecological values understanding that we are only the custodians of our mother earth and that we have no right to destroy nature. I am glad to know that MISAA is involved in different charitable activities like providing books and uniforms for the poor students and helping them to look at the future with hope.


                                </h5>
                                <h5 className='textjustify mb-3'>I take this opportunity to congratulate you for the mile stone you have achieved by giving MISAA a new identity in the Civil Society by registering it under the West Bengal Societies of Registration Act 1961.   Thank you for the efforts that you put in.
                                </h5>
                                <h5 className='textjustify mb-3'>Please try to achieve your goal  to foster family spirit to strengthen the bond of unity among the members,  uphold  the values and morals that you received in our Institute during your formative years, and be altruistic to reach out to those in need. May God bless your efforts in creating a better society and a better world to live in for someone in need. </h5>

                                <h5 ><b>God bless you all</b></h5>
                                
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />


        </div>
    )
}

export default SisterLalita